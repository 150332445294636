<template>
  <div class="home-container">
    <div class="info-container">
      <span class="display_name">{{ name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['name'])
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.home-container {
  .info-container {
    margin-top: 20px;
    color: #fff;
    margin-left: 12px;

    .display_name {
      font-size: 40px;
      font-weight: bold;
    }

    .display_organization {
      font-size: 15px;
      margin-left: 2px;
      margin-top: 40px;
    }
  }
}
</style>
