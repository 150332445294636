<template>
  <div class="dashboard-container">
    <component :is="currentRole" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import memberHome from './member';
import visitorHome from './visitor';

export default {
  components: { memberHome, visitorHome },
  data() {
    return {
      currentRole: 'visitorHome'
    };
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    if (this.userId) {
      this.currentRole = 'memberHome';
    } else {
      this.currentRole = 'visitorHome';
    }
  }
};
</script>
