<template>
  <div class="home-container">
    <div class="info-container">
      <span class="display_name">{{ $t('home.welcomeVisitor') }}</span>
      <br />
      <el-button class="login_button" @click="login">
        {{ $t('login.logIn') }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    login() {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.home-container {
  .info-container {
    margin-top: 20px;
    color: #fff;
    margin-left: 12px;

    .display_name {
      font-size: 40px;
      font-weight: bold;
    }

    .login_button {
      margin-left: 2px;
      margin-top: 40px;
    }
  }
}
</style>
